import React from "react";
import { Link } from "react-router-dom";
import Clients from "./client";
import { useNavigate } from "react-router-dom";

import hero1 from "../../images/slider/right-img2.png";
import hero2 from "../../images/slider/s.png";
import GroupPhoto from "../../images/BB/group-photo.png";

const Hero = () => {
    const navigate = useNavigate();
    const [postcode, setPostcode] = React.useState("");
  
    const handleChange = (e) => {
      setPostcode(e.target.value);
    };

  return (
    <section className="wpo-hero-section-1" style={{paddingTop: "180px"}}>
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col col-xs-6 col-lg-6">
            <div className="wpo-hero-section-text">
              <div className="wpo-hero-title-top">
                <span>Welcome to the</span>
              </div>
              <div className="wpo-hero-title">
                <h2>MANCHESTER AND DISTRICT BATTALION THE BOYS' BRIGADE</h2>
              </div>
              <div className="wpo-hero-subtitle">
                <p>
                  We are one of the biggest Christian Youth Organisations in the
                  UK & Republic of Ireland.
                </p>
              </div>
              <div className="row">
                  <div className="col-6 col-md-4 col-lg-6">
                <input
                  type="text"
                  id="postcode"
                  name="postcode"
                  className="form-control mr-5 h-100"
                  placeholder="Enter your postcode"
                  onChange={(e) => handleChange(e)}

                />
                </div>
                <div className="col-6 col-md-8 col-lg-6">
                <div className="btns">
                <button onClick={() => { navigate(`/locator/${postcode}`); }} className="btn theme-btn-s2">
                  Find your Closest Group
                </button>
              </div>
              </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="right-vec">
              <div className="right-items-wrap">
                <div className="right-item">
                  <div className="r-img">
                    <img
                      src="https://boys-brigade.org.uk/wp-content/uploads/2022/01/web-general-2.jpg"
                      alt=""
                    />
                  </div>
                  {/* <div className="wpo-happy-client">
                    <Clients />
                  </div> */}
                </div>
                <div className="right-item">
                  <div className="r-img" style={{ transform: "rotate(180deg)", marginBottom: "20px" }}>
                    {/* <div className="wpo-total-project-wrap">
                      <div className="wpo-total-project-icon">
                        <i className="fi flaticon-salary"></i>
                      </div>
                      <div className="wpo-total-project-text">
                        <h3>500+</h3>
                        <p>Supporters</p>
                      </div>
                    </div> */}
                    <div className="project-shape" >
                      <img src={GroupPhoto} alt="" />
                    </div>
                  </div>
                  <div className="r-img">
                    <img
                      src="https://boys-brigade.org.uk/wp-content/themes/Boysbrigade/images/supporting.jpg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
