import React, {Fragment} from 'react';
import Navbar from '../../components/Navbar'
import PageTitle from '../../components/pagetitle'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import Logo from '../../images/logo-wbg.svg'
import Locator from '../../components/Locator';

const MapPage =() => {
    return(
        <Fragment>
            <Navbar Logo={Logo}/>
            {/* <PageTitle pageTitle={'Service'} pagesub={'Service'}/>  */}
            <Locator/>
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default MapPage;

