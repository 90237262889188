import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import client1 from '../../../images/slider/1.svg'
import client2 from '../../../images/slider/2.svg'
import client3 from '../../../images/slider/3.svg'
import client5 from '../../../images/slider/5.svg'
import client8 from '../../../images/slider/8.svg'
import client14 from '../../../images/slider/14.svg'
import client24 from '../../../images/slider/24.svg'
import client50 from '../../../images/slider/50.svg'
import client53 from '../../../images/slider/53.svg'
import client55 from '../../../images/slider/55.svg'
import client59 from '../../../images/slider/59.svg'


class Clients extends Component {
    render() {
        var settings = {
            dots: false,
            arrows: false,
            speed: 1000,
            slidesToShow: 5,
            slidesToScroll: 1,
            autoplay: true,
            responsive: [
                {
                    breakpoint: 1500,
                    settings: {
                        slidesToShow: 5,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            <div className="wpo-happy-client-img">
                <ul className="wpo-happy-client-slide">
                    <Slider {...settings}>
                        <li><img src={client2} alt=""/></li>
                        <li><img src={client1} alt=""/></li>
                        <li><img src={client3} alt=""/></li>
                        <li><img src={client5} alt=""/></li>
                        <li><img src={client8} alt=""/></li>
                        <li><img src={client14} alt=""/></li>
                        <li><img src={client24} alt=""/></li>
                        <li><img src={client50} alt=""/></li>
                        <li><img src={client53} alt=""/></li>
                        <li><img src={client55} alt=""/></li>
                        <li><img src={client59} alt=""/></li>
                    </Slider>
                </ul>
            </div>
        );
    }
}

export default Clients;