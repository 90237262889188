import React from 'react'
import {Link} from 'react-router-dom'
// import Services from '../../api/service'
import Anchors from '../../images/BB/anchors_white.png';
import Company from '../../images/BB/company_white.png';
import Juniors from '../../images/BB/juniors_white.png';
import Seniors from '../../images/BB/seniors_white.png';

 const Services = [
    {
        id: '1',
        title:'5-8 years',
        description:`The Anchors are the youngest section of the Boys' Brigade starting at 5 and can be members for three years before moving into the Junior Section when they become 8.`,
        image: Anchors,
        bgColor: '#b22a2e'
    },
    {
        id: '2',
        title:'8-11 years',
        description:'The Junior Section is for young people in years 4, 5 and 6 at Primary School. Young people often come up to Juniors from the Anchors, or are brought along by existing members.',
        image: Juniors,
        bgColor: '#184f90'
    },
    {
        id: '3',
        title:'11-14 years',
        description:'Young people move up to the Company Section when they start secondary school, and advance to the Senior Section when they are 15. Company Section meets each week.',
        image: Company,
        bgColor: '#202946'
    },
    {
        id: '4',
        title:'14-18 years',
        description:'Young people join the Senior Section when they are fifteen. They continue to participate fully in the Company Section activities and have additional time on select days.',
        image: Seniors,
        bgColor: '#691b31'
    },
]

const Service5 = (props) => {
    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }

    return(
        <section className="wpo-features-section-s6 section-padding">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <div className="wpo-section-title">
                            <span>What We Do</span>
                            <h2>JOIN THE ADVENTURE TODAY!</h2>
                            <p style={{width: "100%", maxWidth: "100%"}}>We provide opportunities for children and young people to learn, grow and discover in a safe, fun and caring environment which is rooted in the Christian faith.</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {Services.map((service, sitem) => (
                        <div className="col col-xl-3 col-lg-3 col-sm-6 col-12" key={sitem} >
                            <div className="wpo-features-item">
                                <div className="wpo-features-icon">
                                    <div className="icon" style={{borderRadius: "50%", background: service.bgColor}}>
                                       <img src={service.image} width="90%" />
                                    </div>
                                </div>
                                <div className="wpo-features-text">
                                    <h2>{service.title}</h2>
                                    <p>{service.description} {service.id === "1" ? <span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span> : null} </p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default Service5;