import React, { Component } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import Swal from 'sweetalert2';


class ContactForm extends Component {
    constructor(props) {
        super(props);
    this.recaptchaRef = React.createRef();
    }


    state = {
        childName: '',
        childDob: '',
        childGender: '',
        parentName: '',
        parentEmail: '',
        parentPhone: '',
        parentRelationToChild: '',
        postcode: '',
        error: {}
    }

    urldecode = (str) => {
        return decodeURIComponent((str + '').replace(/\+/g, '%20'));
    }

    getUrlParams = () => {
        const urlParams = window.location.href.split('/');
        const lastParam = urlParams[urlParams.length - 1];
        if (lastParam === 'contact') {
            return 'Contact us page';
        } else if (lastParam === '') {
            return 'Home page';
        }
        else {
            console.log(this.urldecode(lastParam));
            return `Interest in ${this.urldecode(lastParam)}`;
        }
    }

    changeHandler = (e) => {
        const error = this.state.error;
        error[e.target.name] = ''

        this.setState({
            [e.target.name]: e.target.value,
            error
        })
    }

    subimtHandler = (e) => {
        e.preventDefault();
        const token = this.recaptchaRef.current.getValue();
        this.recaptchaRef.current.reset();

        const {
            childName,
            childDob,
            childGender,
            parentName,
            parentEmail,
            parentPhone,
            parentRelationToChild,
            postcode,
            error } = this.state;

        if (childName === '') {
            error.childName = "Please enter the child/young person's name";
        }
        if (childDob === '') {
            error.childDob = "Please enter the child/young person's date of birth";
        }
        if (childGender === '') {
            error.childGender = "Please enter your child/young person's gender";
        }
        if (parentName === '') {
            error.parentName = "Please enter the parent/guardian's lastname";
        }
        if (parentEmail === '') {
            error.parentEmail = "Please enter the parent/guardian's email";
        }
        if (parentPhone === '') {
            error.parentPhone = "Please enter the parent/guardian's phone number";
        }
        if (parentRelationToChild === '') {
            error.parentRelationToChild = "Please enter the parent/guardian's relation to the child";
        }
        if (postcode === '') {
            error.postcode = "Please enter your postcode";
        } 
        if (token === '') {
            error.captcha = "Please complete the captcha";
        } 


        if (error) {
            this.setState({
                error
            })
        }

        if (error.name === '' && error.childName === '' && error.childDob === '' && error.childGender === '' && error.parentName === '' && error.parentEmail === '' && error.parentPhone === '' && error.parentRelationToChild === '' && error.postcode === '' && token !== '') {
            this.setState({
                childName: '',
                childDob: '',
                childGender: '',
                parentName: '',
                parentEmail: '',
                parentPhone: '',
                parentRelationToChild: '',
                postcode: '',
                captcha: '',
                error: {}
            })
        }

        

        if (token) {

        fetch('https://form-api.boysbrigade.workers.dev', {
            method: 'POST',
            mode: 'no-cors',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                childName,
                childDob,
                childGender,
                parentName,
                parentEmail,
                parentPhone,
                parentRelationToChild,
                postcode,
                closestCentre: this.getUrlParams()
            })
        })
            .then(res => res.json())
            .then(data => {
                console.log(JSON.stringify(data));
                this.setState({
                    childName: '',
                    childDob: '',
                    childGender: '',
                    parentName: '',
                    parentEmail: '',
                    parentPhone: '',
                    parentRelationToChild: '',
                    postcode: '',
                })
                Swal.fire({
                    title: 'Thank you!',
                    text: 'Your form has been submitted',
                    icon: 'success',
                    confirmButtonText: 'Continue'
                  });
            })
            .catch(err => {
                console.log(JSON.stringify(err));
                this.setState({
                    childName: '',
                    childDob: '',
                    childGender: '',
                    parentName: '',
                    parentEmail: '',
                    parentPhone: '',
                    parentRelationToChild: '',
                    postcode: '',
                });
                Swal.fire({
                    title: 'Thank you!',
                    text: 'Your form has been submitted',
                    icon: 'success',
                    confirmButtonText: 'Continue',
                    confirmButtonColor: '#2f5ea4',
                  });
            })
        } else {
            Swal.fire({
                title: 'Please complete the captcha',
                icon: 'warning',
                confirmButtonText: 'Okay',
                confirmButtonColor: '#2f5ea4',
              });
        }

    }

    render() {
        const { childName,
            childDob,
            childGender,
            parentName,
            parentEmail,
            parentPhone,
            parentRelationToChild,
            postcode,
            error } = this.state;

        return (
            <form id="main-form" onSubmit={this.subimtHandler} className="form">
                <div className="row">
                    <h3>Child/Young Person’s Details</h3>
                </div>
                <div className="row justify-content-start">
                    <div className="col-lg-6 col-md-6 col-12">
                        <div className="form-field">
                            <input value={childName} onChange={this.changeHandler} type="text" name="childName" placeholder="Child/Young person's name" />
                            <p>{error.childName ? error.childName : ''}</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                        <div className="form-field">
                            <input value={childDob} onChange={this.changeHandler} type="text" name="childDob" placeholder="Child/Young person's Date of Birth" />
                            <p>{error.childDob ? error.childDob : ''}</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                        <div className="form-field">
                            {/* <label className='pl-2'>Child/Young person's Sex</label> */}
                            <select className="form-control" onChange={this.changeHandler} value={childGender} type="text" name="childGender">
                                <option value={""}>Please Select Child/Young person's Sex</option>
                                <option value={"male"}>Male</option>
                                <option value={"female"}>Female</option>
                            </select>
                            <p>{error.childGender ? error.childGender : ''}</p>
                        </div>
                    </div>
                    </div>
                    <div className="row">
                    <h3>Parent/Guardian Details</h3>
                </div>
                    <div className="row justify-content-start">
                    <div className="col-lg-6 col-md-6 col-12">
                        <div className="form-field">
                            <input onChange={this.changeHandler} value={parentName} type="text" name="parentName" placeholder="Parent/Guardian's Name" />
                            <p>{error.parentName ? error.parentName : ''}</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                        <div className="form-field">
                            <input onChange={this.changeHandler} value={parentEmail} type="email" name="parentEmail" placeholder="Parent/Guardian's Email" />
                            <p>{error.parentEmail ? error.parentEmail : ''}</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                        <div className="form-field">
                            <input onChange={this.changeHandler} value={parentPhone} type="text" name="parentPhone" placeholder="Parent/Guardian's Phone" />
                            <p>{error.parentPhone ? error.parentPhone : ''}</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                        <div className="form-field">
                            <input onChange={this.changeHandler} value={parentRelationToChild} type="text" name="parentRelationToChild" placeholder="Relationship to child/Young Person" />
                            <p>{error.parentRelationToChild ? error.parentRelationToChild : ''}</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                        <div className="form-field">
                            <input onChange={this.changeHandler} value={postcode} type="text" name="postcode" placeholder="Your Postcode" />
                            <p>{error.postcode ? error.postcode : ''}</p>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                    <ReCAPTCHA
                    className="pt-3"
                    sitekey={process.env.REACT_APP_SITE_KEY} 
                    ref={this.recaptchaRef}
                    />
                    {/* <p style={{color: 'red'}}>{error.captcha ? error.captcha : ''}</p> */}
                        <p className="pt-3">Once the form has been submitted, a member of our team will get in touch with you</p>
                        </div>
                    <div className="col-lg-12">
                        <div className="form-submit">
                            <button type="submit" className="theme-btn" style={{width: "200px"}}>Send</button>
                        </div>
                    </div>
                </div>
            </form>
        )
    }

}
export default ContactForm;