import React from "react";
import { useParams } from "react-router-dom";
import ContactForm from '../ContactFrom'

const CompanyForm = (props) => {
  const { companyName } = useParams();
  const [title, setTitle] = React.useState("");



  React.useEffect(() => {
    window.scrollTo(0, 0);
    if (companyName) {
      setTitle(companyName);
    }
  }, [companyName]);


  return (
    <section id="joinusform" className="wpo-contact-pg-section" style={{paddingBottom: "180px", paddingTop: props.TopPadding}}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="wpo-section-title">
              <span>Join us</span>
              <h2>Register your Interest {title && "with: " + title}</h2>
              <p style={{width: "100%", maxWidth: "100%"}}>
                We provide opportunities for children and young people to learn,
                grow and discover in a safe, fun and caring environment which is
                rooted in the Christian faith.
              </p>
            </div>
          </div>
        </div>
        <div className="wpo-contact-form-area">
                            <ContactForm/>
                        </div>
      </div>
    </section>
  );
};

export default CompanyForm;
