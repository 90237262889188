import React from "react";
import { Map, Marker } from "pigeon-maps";
import { maptiler } from "pigeon-maps/providers";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid } from "@material-ui/core";
import { Link, useParams, useNavigate } from "react-router-dom";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "none",
    borderRadius: "15px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3),
    outline: "none",
  },
}));

const maptilerProvider = maptiler("neGIwCsDaAzdpTumTtBk", "winter");

const data = [
  {
    id: 1,
    title: "53rd Manchester",
    longitude: 53.4861299,
    latitude: -2.2964993,
    details: {
      centre: "Emmanuel Church LEP",
      address: "Emmanuel Church LEP, 174 Langworthy Road, Salford, M6 5PN",
      anchor: "Monday",
      junior: "Monday",
      company: "Monday",
      seniors: "Monday",
      boysOnly: false,
    },
  },
  {
    id: 2,
    title: "59th Manchester",
    longitude: 53.4476118,
    latitude: -2.2372379,
    details: {
      centre: "Fallowfield Methodist Community Church",
      address: "Fallowfield Methodist Community Church, Platt Lane, Manchester, M14 7BU",
      anchor: "Friday",
      junior: "Friday",
      company: "Friday",
      boysOnly: true,
    },
  },
  {
    id: 3,
    title: "3rd Trafford",
    longitude: 53.4490882,
    latitude: -2.3183852,
    details: {
      centre: "Sevenways Methodist Church",
      address: "Barton Road, Stretford, Manchester, M32 9RD",
      anchor: "Wednesday",
      junior: "Wednesday",
      company: "Wednesday",
      seniors: "Friday",
      boysOnly: true,
    },
  },
  {
    id: 4,
    title: "5th Manchester",
    longitude: 53.4217589,
    latitude: -2.2203696,
    details: {
      centre: "Christ Church South Manchester",
      address: "Christ Church Parrs Wood Rd URC, Parrs Wood Road, Didsbury, Manchester, M20 6EE",
      anchor: "Wednesday",
      junior: "Friday",
      company: "Wednesday",
      boysOnly: true,
    },
  },
  {
    id: 5,
    title: "50th Manchester",
    longitude: 53.3824219,
    latitude: -2.2599881,
    details: {
      centre: "Brownley Green Baptist Church",
      address: "Brownley Green Baptist Church, Crossacres Road, Wythenshawe, Manchester, M22 9TB",
      anchor: "Monday",
      junior: "Monday",
      company: "Monday",
      seniors: "Monday",
      boysOnly: true,
    },
  },
  {
    id: 6,
    title: "1st Denton",
    longitude: 53.454467,
    latitude: -2.1154132,
    details: {
      centre: "Hope United Reformed Church",
      address: "Hope United Reformed Church, Stockport Road, Denton, Manchester, Lancashire, M34 6AB",
      anchor: "Friday",
      junior: "Friday",
      company: "Friday",
      seniors: "Friday",
      boysOnly: true,
    },
  },
  {
    id: 7,
    title: "2nd Denton",
    longitude: 53.4555359,
    latitude: -2.1141042,
    details: {
      centre: "Denton Methodist",
      address: "Denton methodist, Stockport Road, Denton, Manchester, Lancashire, M34 6AB",
      anchor: "Thursday",
      junior: "Thursday",
      company: "Thursday",
      seniors: "Thursday",
      boysOnly: true,
    },
  },
  {
    id: 8,
    title: "2nd Altrincham",
    longitude: 53.3906483,
    latitude: -2.3502991,
    details: {
      centre: "Altrincham Methodist Church",
      address: "Altrincham Methodist Church, Barrington Road, Altrincham, Cheshire, WA14 1HF",
      anchor: "Monday",
      junior: "Monday",
      company: "Monday",
      seniors: "Monday",
      boysOnly: true,
    },
  },
  {
    id: 9,
    title: "8th Trafford",
    longitude: 53.4560871,
    latitude: -2.3610523,
    details: {
      centre: "Cornerstone Methodist Church",
      address: "Cornerstone Methodist Church, Hayswater Road, Urmston, Manchester, M41 7BL",
      anchor: "Thursday",
      junior: "Thursday",
      company: "Thursday",
      seniors: "Thursday",
      boysOnly: true,
    },
  },
  {
    id: 10,
    title: "1st Altrincham",
    longitude: 53.3923102,
    latitude: -2.3719532,
    details: {
      centre: "The Church on the Brow",
      address: "Oldfield Congregational, Taylor Road, Altrincham, Cheshire, WA14 4JL",
      anchor: "Monday",
      junior: "Monday",
      company: "Monday",
      seniors: "Monday",
      boysOnly: true,
    },
  },
  {
    id: 11,
    title: "55th Manchester",
    longitude: 53.486407,
    latitude: -2.3374564,
    details: {
      centre: "Eccles Congregational Church",
      address: "Eccles Congregational Church, Wellington Road, Eccles, Greater Manchester, M30 9AL",
      anchor: "Friday",
      junior: "Friday",
      company: "Friday",
      seniors: "Friday",
      boysOnly: false,
    },
  },
  {
    id: 12,
    title: "24th Oldham",
    longitude: 53.5308376,
    latitude: -2.1487583,
    details: {
      centre: "South Chadderton Methodist",
      address: "South Chadderton Methodist, Thompson Lane, Chadderton, Oldham, OL9 8LX",
      anchor: "Wednesday",
      junior: "Wednesday",
      company: "Wednesday",
      seniors: "Wednesday",
      boysOnly: false,
    },
  },
  {
    id: 13,
    title: "1st Chapel",
    longitude: 53.3245063,
    latitude: -1.911239,
    details: {
      centre: "Town End Methodist Church",
      address: "Town End Methodist Church, Market Street, Chapel-en-le-Frith, High Peak, SK23 0JD",
      anchor: "Monday",
      junior: "Monday",
      company: "Monday",
      seniors: "Monday",
      boysOnly: true,
    },
  },
  {
    id: 14,
    title: "14th Bolton",
    longitude: 53.5804471,
    latitude: -2.406664,
    details: {
      centre: "Trinity Methodist Church",
      address: "Trinity Methodist Church, 18 Tonge Old Road, Tonge Fold, Bolton, BL2 6BH",
      anchor: "Monday",
      junior: "Monday",
      company: "Monday",
      seniors: "Monday",
      boysOnly: true,
    },
  },
];



const Locator = (props) => {
  const { userpc } = useParams();
  const [open, setOpen] = React.useState(false);
  const [itemHolder, setItemHolder] = React.useState(null);
  const [postcode, setPostcode] = React.useState("");
  const [center, setCenter] = React.useState([53.4776036, -2.2470562]);
  const [zoom, setZoom] = React.useState(11);
  const navigate = useNavigate();

  // onload scroll to top
  React.useEffect(() => {
    window.scrollTo(0, 0);
  } , []);
  

 // input onchange function
  const handleChange = (e) => {
    setPostcode(e.target.value);
  };

  React.useEffect(() => {
    if (userpc) {
      setPostcode(userpc);
      searchPostcodeFromURL(userpc);
    }
  }, [userpc]);


  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  const handleOpen = (item) => {
    setOpen(true);
    setItemHolder(item);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  const handleClick = (item) => {
    navigate(`/registerwith/${item && item.title}`);
  }


  const searchPostcodeFromURL = (postcode) => {
    const url = `https://api.postcodes.io/postcodes/${postcode}`;
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setCenter([data.result.latitude, data.result.longitude]);
      })
      .catch((err) => console.log(err));
  };

  const searchPostcode = (e) => {
    e.preventDefault();
    const url = `https://api.postcodes.io/postcodes/${postcode}`;
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setCenter([data.result.latitude, data.result.longitude]);
      })
      .catch((err) => console.log(err));
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <h2 id="simple-modal-title">
        {itemHolder === null ? "" : itemHolder.title}
      </h2>
      <p id="simple-modal-description">
        {itemHolder === null ? "" : itemHolder.details.address}
      </p>
      <div className="btns mt-5">
            <button onClick={() => handleClick(itemHolder)} className="btn theme-btn-s2 w-100">Register Your Interest</button>
          </div>
    </div>
  );

  return (
    <section className="wpo-features-section-s6" style={{paddingTop: "180px"}}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="wpo-section-title">
              <span>Join us</span>
              <h2>JOIN THE ADVENTURE TODAY!</h2>
              <p style={{width: "100%", maxWidth: "100%"}}>
                We provide opportunities for children and young people to learn,
                grow and discover in a safe, fun and caring environment which is
                rooted in the Christian faith.
              </p>
            </div>
          </div>
        </div>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={3}
        >
           <Grid item xs={12}  md={2}>
          <h3>Postcode: </h3>
          </Grid>
          <Grid item xs={8} md={5}>
          <input
          style={{paddingTop: "10px", paddingBottom: "10px"}}
            type="text"
            id="postcode"
            name="postcode"
            className="form-control"
            placeholder="Enter postcode"
            onChange={(e) => handleChange(e)}
          />
</Grid>
          <Grid item xs={4} md={2}>
          <div className="btns">
            <button onClick={(e) => {searchPostcode(e)}} className="btn theme-btn-s2 w-100">Search</button>
          </div>
          </Grid>
        </Grid>
        <div className="row mt-5">
          <Map
            provider={maptilerProvider}
            height={600}
            zoom={zoom}
            center={center}
          >
            {/* {center !== [53.4776036, -2.2470562] ? <Marker width={50} color="#000" anchor={center}/> : null} */}
            {data.map((item, index) => (
              <Marker
              key={index}
                width={50}
                color="#145cbb"
                anchor={[item.longitude, item.latitude]}
                onClick={() => {
                  handleOpen(item);
                }}
              />
            ))}
          </Map>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            {body}
          </Modal>
        </div>
      </div>
    </section>
  );
};

export default Locator;
